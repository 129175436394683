import { action, computed, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { CostApi, FlowApi } from 'core/services/api';
import { Flow } from 'types/flow';
import { Cost } from 'types/cost';

export class CostsStore {
  @observable private allFlows: Flow[] = [];
  @observable private allCosts: Cost[] = [];

  @observable public isLoading: boolean = true;
  @observable public editingCost: { id: string; cost: number } | null = null;

  constructor() {
    makeObservable(this);
  }

  @computed public get flows(): Flow[] {
    return this.allFlows.filter(
      (flow) => !this.allCosts.find((cost) => cost.flow_id === flow.id),
    );
  }

  @computed public get costs(): Cost[] {
    return this.allCosts.map((cost) => ({
      ...cost,
      flowName:
        this.allFlows.find((flow) => flow.id === cost.flow_id)?.name || '',
    }));
  }

  @action public init = async () => {
    try {
      const [{ costs }, { flows }] = await Promise.all([
        CostApi.find(),
        FlowApi.find(),
      ]);

      this.allCosts = costs;
      this.allFlows = flows;

      this.isLoading = false;
    } catch (e: any) {
      notification.error({ message: 'error init', description: e.toString() });
    }
  };

  @action public remove = async (cost: Cost) => {
    const index = this.allCosts.findIndex((item) => item.id === cost.id);

    await CostApi.remove(cost.id);

    if (index !== -1) {
      this.allCosts.splice(index, 1);
    }
  };

  @action public create = async (flowId: string, cost: number) => {
    try {
      if (!flowId) {
        throw new Error('flow id is required');
      }

      if (!cost) {
        throw new Error('cost is required');
      }

      const createdCost = await CostApi.create(flowId, cost);

      this.allCosts.push(createdCost);
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });

      throw e;
    }
  };

  @action public setEditing = (cost: Cost | null = null) => {
    if (cost) {
      this.editingCost = { id: cost.id, cost: cost.cost };
    } else {
      this.editingCost = null;
    }
  };

  @action public setEditingCostValue = (cost: number | null) => {
    if (this.editingCost && cost) {
      this.editingCost.cost = cost;
    }
  };

  @action public edit = async () => {
    if (this.editingCost) {
      const cost = await CostApi.edit(
        this.editingCost.id,
        this.editingCost.cost,
      );

      const index = this.allCosts.findIndex(
        (item) => item.id === this.editingCost?.id,
      );

      this.allCosts[index] = cost;

      this.editingCost = null;
    }
  };
}
