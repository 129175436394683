import { action, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { Module } from 'types/module';
import { NavigateFunction } from 'react-router-dom';
import { setLink } from 'core/services/url/link';
import { ROUTES_MAP } from 'components/config';
import { ModuleApi } from 'core/services/api';
import { DependencyStore } from './dependency.store';

export class ModuleStore {
  private readonly id: string;
  private navigate: NavigateFunction;

  public readonly isNew: boolean = false;

  @observable public isLoading: boolean = true;
  @observable public module: Module | null = null;
  @observable public dependencies: DependencyStore[] = [];
  @observable public modules: Module[] = [];

  constructor(id: string, navigate: NavigateFunction) {
    this.id = id;

    if (id === 'new') {
      this.isNew = true;
    }

    this.navigate = navigate;

    makeObservable(this);
  }

  @action public init = async () => {
    try {
      if (!this.isNew) {
        const [module, { modules }] = await Promise.all([
          ModuleApi.findById(this.id),
          ModuleApi.find(),
        ]);

        this.module = module;
        this.modules = modules;

        const { dependencies } = await ModuleApi.findDependencies(this.id);

        this.dependencies = dependencies.map((item) => {
          return new DependencyStore(item);
        });
      } else {
        this.module = {
          created_at: new Date().toString(),
          description: '',
          id: 'new',
          name: '',

          config: { version: '' },
        };
      }
    } catch (e: any) {
      notification.error({ message: 'error init', description: e.toString() });
    } finally {
      this.isLoading = false;
    }
  };

  @action public setName = (name: string) => {
    if (this.module) {
      this.module.name = name;
    }
  };

  @action public setDescription = (description: string) => {
    if (this.module) {
      this.module.description = description;
    }
  };

  @action public create = async () => {
    try {
      this.isLoading = true;

      if (this.module) {
        const module = await ModuleApi.create(
          this.module?.name,
          this.module?.description,
        );

        window.location.href = setLink(ROUTES_MAP.module, { id: module.id });
      }
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });

      this.isLoading = false;
    }
  };
}
