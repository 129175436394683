import { Modal, notification } from 'antd';
import React from 'react';
import { Input } from 'components/presenters';

interface Props {
  visible: boolean;
  onSave: (url: string) => void;
  onClose: () => void;
}

interface State {
  url: string;
}

class CreateContent extends React.Component<Props, State> {
  state: State = {
    url: '',
  };

  handleChangeUrl = (e: any) => {
    this.setState({ url: e.target.value });
  };

  onSave = () => {
    const { onSave } = this.props;
    const { url } = this.state;

    if (!url) {
      notification.error({
        message: 'create error',
        description: 'url is required',
      });
      return;
    }

    onSave(url);
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState({ url: '' });

    onClose();
  };

  render() {
    const { visible } = this.props;
    const { url } = this.state;

    return (
      <Modal
        title="Create content"
        visible={visible}
        onOk={this.onSave}
        onCancel={this.onClose}
      >
        <div>
          <Input
            placeholder="Enter file url..."
            value={url}
            onChange={this.handleChangeUrl}
          />
        </div>
      </Modal>
    );
  }
}

export default CreateContent;
