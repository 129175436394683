import { notification } from 'antd';
import { AuthStore } from 'core/stores/auth.store';
import { makeAutoObservable, observable } from 'mobx';

export class LoginStore {
  private authStore: AuthStore;

  @observable public error: string = '';
  @observable public loading: boolean = false;

  constructor(authStore: AuthStore) {
    makeAutoObservable(this);
    this.authStore = authStore;
  }

  submit = async (token: string) => {
    try {
      this.loading = true;
      this.error = '';

      await this.authStore.verify(token);
    } catch (e: any) {
      notification.error({
        message: 'token not valid',
        description: e.toString(),
      });
    } finally {
      this.loading = false;
    }
  };
}
