import { Load } from 'components/presenters';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import React from 'react';
import { AssetsStore } from '../../services/assets.store';
import AssetsItem from '../assets-item/assets-item';
import InfinityScroll from 'react-infinite-scroll-component';

import styles from './assets-list.module.less';

interface Props {
  assetsStore: AssetsStore;
}

@inject('assetsStore')
@observer
class AssetsList extends React.Component<Props> {
  render() {
    const { assetsStore } = this.props;

    if (!assetsStore.assets.length) {
      return (
        <div className={styles.notFound}>
          <h3>Not Found</h3>
        </div>
      );
    }

    return (
      <Load loading={assetsStore.loadingAssets}>
        <div className={styles.list}>
          <InfinityScroll
            dataLength={assetsStore.assets.length}
            next={assetsStore.loadMore}
            hasMore={assetsStore.pagination.total > assetsStore.assets.length}
            loader={<Load loading />}
          >
            {assetsStore.assets.map((asset) => (
              <AssetsItem key={asset.id} asset={asset} />
            ))}
          </InfinityScroll>
        </div>
      </Load>
    );
  }
}

export default observerWrapper<{}>(AssetsList);
