import { useState } from 'react';
import { Modal, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { CostsStore } from '../../services/costs.store';
import { observerWrapper } from 'observer-wrapper';
import { Button, Input } from 'components/presenters';

import styles from './create-cost.module.less';

interface Props {
  costsStore: CostsStore;
}

const CreateCost = inject('costsStore')(
  observer(({ costsStore }: Props) => {
    const [creating, setCreating] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);
    const [flowId, setFlowId] = useState(undefined);
    const [cost, setCost] = useState<number | null>(null);

    const setVisibleHandler = (visible: boolean) => () => {
      setVisible(visible);
    };

    const onCreate = async () => {
      if (flowId && cost) {
        try {
          setCreating(true);
          await costsStore.create(flowId, cost);
          setVisible(false);
        } catch (e) {
        } finally {
          setCreating(false);
        }
      }
    };

    return (
      <div className={styles.container}>
        <Button type="primary" onClick={setVisibleHandler(true)}>
          Create
        </Button>
        <Modal
          title="Create cost"
          visible={visible}
          onCancel={setVisibleHandler(false)}
          okButtonProps={{ disabled: !flowId || !cost }}
          onOk={onCreate}
          confirmLoading={creating}
        >
          <div className={styles.form}>
            <Select
              size="large"
              showSearch
              placeholder="Please select flow"
              value={flowId}
              onChange={(value) => setFlowId(value)}
              filterOption={(input, option) => {
                return Boolean(
                  option?.label
                    ?.toString()
                    .toUpperCase()
                    .includes(input.toUpperCase()),
                );
              }}
            >
              {costsStore.flows.map((flow) => (
                <Select.Option key={flow.id} value={flow.id} label={flow.name}>
                  {flow.name}
                </Select.Option>
              ))}
            </Select>
            <Input.Number
              style={{ width: '100%' }}
              placeholder="Please enter cost"
              size="large"
              precision={0}
              min={1}
              onChange={setCost}
              value={cost}
            />
          </div>
        </Modal>
      </div>
    );
  }),
);

export default observerWrapper(CreateCost);
