import { DependencyStore } from '../../services/dependency.store';
import { ModuleDependencyType } from 'types/module';
import { setLink } from 'core/services/url/link';
import { ROUTES_MAP } from 'components/config';
import { Button, Input } from 'components/presenters';
import React from 'react';
import { Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { ModuleStore } from '../../services/module.store';
import { observerWrapper } from 'observer-wrapper';

interface ExternalProps {
  dependency: DependencyStore;
}

interface Props extends ExternalProps {
  moduleStore: ModuleStore;
}

const Requirement = inject('moduleStore')(
  observer(({ dependency, moduleStore }: Props) => {
    const handleChange = (e: any) => {
      dependency.setRequirementId(e.target.value);
    };

    const getRequirementName = (item: DependencyStore): string => {
      let requirementName = item.dependency.requirement_id;

      if (item.dependency.type !== ModuleDependencyType.config) {
        requirementName =
          moduleStore.modules.find(
            (module) => module.id === item.dependency.requirement_id,
          )?.name || '';
      }

      return requirementName;
    };

    if (dependency.isView) {
      if (dependency.dependency.type !== ModuleDependencyType.config) {
        return (
          <a
            href={setLink(ROUTES_MAP.module, {
              id: dependency.dependency.requirement_id,
            })}
          >
            <Button>{getRequirementName(dependency)}</Button>
          </a>
        );
      }
      return <div>{dependency.dependency.requirement_id}</div>;
    }

    if (dependency.dependency.type === ModuleDependencyType.config) {
      return (
        <Input
          onChange={handleChange}
          value={dependency.dependency.requirement_id}
        />
      );
    }

    return (
      <Select
        value={dependency.dependency.requirement_id}
        style={{ width: '100%' }}
        onChange={dependency.setRequirementId}
      >
        {moduleStore.modules.map((module) => (
          <Select.Option key={module.id} value={module.id}>
            {module.name}
          </Select.Option>
        ))}
      </Select>
    );
  }),
);

export default observerWrapper<ExternalProps>(Requirement);
