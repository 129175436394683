import React from 'react';
import { Button, Load } from 'components/presenters';
import { observerWrapper } from 'observer-wrapper';
import { CostsStore } from '../services/costs.store';
import { inject, observer, Provider } from 'mobx-react';
import { Table } from 'antd';
import { Cost } from 'types/cost';

import styles from './costs.module.less';
import CreateCost from '../components/create-cost/create-cost';
import EditCost from '../components/edit-cost/edit-cost';

interface Props {
  costsStore: CostsStore;
}

const columns = [
  { title: 'Flow', dataIndex: 'flowName' },
  { title: 'Cost', dataIndex: 'cost' },
];

@inject('costsStore')
@observer
class Costs extends React.Component<Props> {
  componentDidMount() {
    this.props.costsStore.init();
  }

  remove = (cost: Cost) => () => {
    this.props.costsStore.remove(cost);
  };

  render() {
    const { costsStore } = this.props;

    return (
      <Load loading={costsStore.isLoading}>
        <div className={styles.container}>
          <div className={styles.content}>
            <CreateCost />
            <EditCost />
            <Table
              rowKey="id"
              columns={[
                ...columns,
                {
                  title: 'Actions',
                  render: (cost: Cost) => {
                    return (
                      <div className={styles.actions}>
                        <Button onClick={() => costsStore.setEditing(cost)}>
                          Edit
                        </Button>
                        <Button
                          type="primary"
                          danger
                          onClick={this.remove(cost)}
                        >
                          Remove
                        </Button>
                      </div>
                    );
                  },
                },
              ]}
              dataSource={costsStore.costs}
              pagination={false}
            />
          </div>
        </div>
      </Load>
    );
  }
}

const CostsWrapper = observerWrapper(Costs);

const wrapper = () => {
  const costsStore = new CostsStore();

  return (
    <Provider costsStore={costsStore}>
      <CostsWrapper />
    </Provider>
  );
};

export default wrapper;
