import { ADMIN_API_URL } from 'configs/api.config';
import { Asset } from 'types/asset';
import { httpDelete, httpGet, httpPost, httpPut } from './http';
import { Content } from 'types/content';
import { Process, ProcessInput } from 'types/process';
import { Result } from 'types/result';
import { Feature } from 'types/feature';
import { Segment } from 'types/segment';

export class AssetApi {
  static async find(
    query: string,
    offset: number,
    limit: number,
  ): Promise<{ assets: Asset[]; pagination: any }> {
    return httpGet(
      `${ADMIN_API_URL}/asset?offset=${offset}&limit=${
        limit || ''
      }&query=${query}`,
    );
  }

  static async findById(id: string) {
    return httpGet(`${ADMIN_API_URL}/asset/${id}`);
  }

  static async findContents(id: string): Promise<{ contents: Content[] }> {
    return httpGet(`${ADMIN_API_URL}/asset/${id}/content`);
  }

  static async findProcesses(id: string): Promise<{ processes: Process[] }> {
    return httpGet(`${ADMIN_API_URL}/asset/${id}/process`);
  }

  static async findFeatures(id: string, processId: string): Promise<Feature[]> {
    return httpGet(`${ADMIN_API_URL}/asset/${id}/process/${processId}/feature`);
  }

  static async findSegments(
    id: string,
    processId: string,
    featureId: string,
  ): Promise<Segment[]> {
    return httpGet(
      `${ADMIN_API_URL}/asset/${id}/process/${processId}/feature/${featureId}/segment`,
    );
  }

  static async createProcess(
    id: string,
    title: string,
    flowId: string,
    input: ProcessInput,
    isDummy: boolean,
  ) {
    return httpPost(`${ADMIN_API_URL}/asset/${id}/process`, {
      body: JSON.stringify({
        flow_id: flowId,
        inputs: [input],
        is_dummy: isDummy,
        title,
      }),
    });
  }

  static async removeProcess(id: string, processId: string) {
    return httpDelete(`${ADMIN_API_URL}/asset/${id}/process/${processId}`);
  }

  static async stopProcess(id: string, processId: string) {
    return httpPut(`${ADMIN_API_URL}/asset/${id}/process/${processId}/stop`);
  }

  static async findResults(id: string, processId: string): Promise<Result[]> {
    return httpGet(`${ADMIN_API_URL}/asset/${id}/process/${processId}/result`);
  }

  static async downloadVersion(
    id: string,
    processId: string,
    resultId: string,
    resultVersionId: string,
  ): Promise<any> {
    return httpGet(
      `${ADMIN_API_URL}/asset/${id}/process/${processId}/result/${resultId}/${resultVersionId}`,
    );
  }

  static async createVersion(
    id: string,
    processId: string,
    resultId: string,
    json: any,
  ) {
    return httpPost(
      `${ADMIN_API_URL}/asset/${id}/process/${processId}/result/${resultId}/version`,
      {
        body: JSON.stringify({
          body: json,
        }),
      },
    );
  }

  static async createResult(
    id: string,
    processId: string,
    name: string,
    type: string,
  ) {
    return httpPost(
      `${ADMIN_API_URL}/asset/${id}/process/${processId}/result`,
      {
        body: JSON.stringify({
          name,
          type,
        }),
      },
    );
  }

  static async createContent(id: string, url: string) {
    return httpPost(`${ADMIN_API_URL}/asset/${id}/content`, {
      body: JSON.stringify({
        url,
      }),
    });
  }

  static async copy(id: string, accountId: string) {
    return httpPost(`${ADMIN_API_URL}/asset/${id}/copy`, {
      body: JSON.stringify({
        account_id: accountId,
      }),
    });
  }

  static async remove(id: string) {
    return httpDelete(`${ADMIN_API_URL}/asset/${id}`);
  }
}
