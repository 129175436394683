import { inject, observer } from 'mobx-react';
import React from 'react';
import { AuthStore } from 'core/stores/auth.store';
import { LoginStore } from '../services/login.store';
import { observerWrapper } from 'observer-wrapper';
import { Button, Input } from 'components/presenters';

import styles from './login.module.less';
import { Form } from 'antd';

interface WrapperProps {
  authStore: AuthStore;
}

interface InternalProps {
  loginStore: LoginStore;
}

@observer
class Login extends React.Component<InternalProps> {
  onSubmit = (values: any) => {
    const { loginStore } = this.props;

    loginStore.submit(values.token);
  };

  render() {
    const { error, loading } = this.props.loginStore;
    return (
      <div className={styles.page}>
        <Form onFinish={this.onSubmit}>
          <div className={styles.form}>
            <h2>Sign In to continue</h2>
            <div className={styles.formItem}>
              <Form.Item
                name="token"
                rules={[
                  { required: true, message: 'Please input your token!' },
                ]}
              >
                <Input
                  className={styles.input}
                  placeholder="token"
                  size="large"
                  autoFocus
                  type="password"
                />
              </Form.Item>
            </div>
            {error && <div style={{ color: '#f44336' }}>{error}</div>}
            <div className={styles.formItem}>
              <Button
                loading={loading}
                type="primary"
                htmlType="submit"
                size="large"
                block
                shape="round"
              >
                Sign In
              </Button>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

const wrapper = inject('authStore')((props: WrapperProps) => {
  const loginStore = new LoginStore(props.authStore);

  return <Login loginStore={loginStore} />;
});

export default observerWrapper<{}>(wrapper);
