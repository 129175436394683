import React from 'react';
import { Routes } from 'components/routes';
import { BrowserRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AppStore } from 'core/stores/app.store';
import { AuthStore } from 'core/stores/auth.store';
import { observerWrapper } from 'observer-wrapper';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

interface Props {
  appStore: AppStore;
  authStore: AuthStore;
}

@inject('appStore', 'authStore')
@observer
class App extends React.Component<Props> {
  componentDidMount() {
    this.props.appStore.init();
  }

  render() {
    const { appStore, authStore } = this.props;
    return (
      <main>
        {appStore.isReady ? (
          <React.Fragment>
            <BrowserRouter>
              <Routes loggedIn={authStore.loggedIn} />
            </BrowserRouter>
          </React.Fragment>
        ) : (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Spin size="large" indicator={antIcon} />
          </div>
        )}
      </main>
    );
  }
}

export default observerWrapper<{}>(App);
