import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import styles from './load.module.less';

const antIcon = <LoadingOutlined style={{ fontSize: 64 }} spin />;

interface Props {
  children?: any;
  loading?: boolean;
}

const Load = (props: Props) => {
  if (props.loading) {
    return (
      <div className={styles.container}>
        <Spin className={styles.spin} indicator={antIcon} />
      </div>
    );
  }

  return props.children;
};

export default Load;
