import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import React from 'react';
import ModulesItem from '../modules-item/modules-item';
import { ModulesStore } from '../../services/modules.store';

import styles from './modules-list.module.less';

interface Props {
  modulesStore: ModulesStore;
}

@inject('modulesStore')
@observer
class ModulesList extends React.Component<Props> {
  render() {
    const { modulesStore } = this.props;

    if (!modulesStore.modules.length) {
      return (
        <div className={styles.notFound}>
          <h3>Not Found</h3>
        </div>
      );
    }

    return (
      <div className={styles.list}>
        {modulesStore.modules.map((module) => (
          <ModulesItem key={module.id} module={module} />
        ))}
      </div>
    );
  }
}

export default observerWrapper<{}>(ModulesList);
