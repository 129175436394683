import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ModuleStore } from '../services/module.store';
import { inject, observer, Provider } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { Button, Input, Load } from 'components/presenters';

import styles from './module.module.less';
import ModuleContent from '../components/module-content/module-content';

interface Props {
  moduleStore: ModuleStore;
}

@inject('moduleStore')
@observer
class Module extends React.Component<Props> {
  componentDidMount() {
    this.props.moduleStore.init();
  }

  handleNameChange = (e: any) => {
    this.props.moduleStore.setName(e.target.value);
  };

  handleDescriptionChange = (e: any) => {
    this.props.moduleStore.setDescription(e.target.value);
  };

  render() {
    const { moduleStore } = this.props;

    return (
      <Load loading={moduleStore.isLoading}>
        <div className={styles.container}>
          <div className={styles.content}>
            {moduleStore.isNew ? (
              <div className={styles.new}>
                <div className={styles.newTitle}>
                  <h2>Create new module</h2>
                </div>
                <div>
                  <Input
                    size="large"
                    placeholder="Enter module name"
                    value={moduleStore.module?.name}
                    onChange={this.handleNameChange}
                  />
                </div>
                <div>
                  <Input.TextArea
                    placeholder="Enter module description"
                    value={moduleStore.module?.description}
                    onChange={this.handleDescriptionChange}
                  />
                </div>
                <div>
                  <Button
                    type="primary"
                    size="large"
                    disabled={
                      !moduleStore.module?.name ||
                      !moduleStore.module.description
                    }
                    onClick={moduleStore.create}
                  >
                    Create
                  </Button>
                </div>
              </div>
            ) : (
              moduleStore.module && (
                <ModuleContent module={moduleStore.module} />
              )
            )}
          </div>
        </div>
      </Load>
    );
  }
}

const ModuleWrapper = observerWrapper(Module);

function Wrapper() {
  const params = useParams();
  const navigate = useNavigate();

  const id = params['id'] || '';

  const moduleStore = new ModuleStore(id, navigate);

  return (
    <Provider moduleStore={moduleStore}>
      <ModuleWrapper />
    </Provider>
  );
}

export default Wrapper;
