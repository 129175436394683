import { action, makeObservable, observable } from 'mobx';
import { Asset } from 'types/asset';
import { notification } from 'antd';
import { Account } from 'types/account';
import { AssetApi, AuthApi } from 'core/services/api';
import { debounce } from '@antv/x6/lib/util/function/function';

export class AssetsStore {
  private accounts: Account[] = [];

  @observable public assets: Asset[] = [];
  @observable public loading: boolean = true;
  @observable public loadingAssets: boolean = false;
  @observable public filters = {
    query: new URLSearchParams(window.location.search).get('query') || '',
  };
  @observable public pagination = {
    total: 0,
    offset: 0,
    limit: 50,
  };

  constructor() {
    makeObservable(this);
  }

  @action private loadAssetsDebounce = debounce(async () => {
    this.loadingAssets = true;
    this.pagination.offset = 0;
    await this.loadAssets();
    this.loadingAssets = false;
  }, 300);

  @action private loadAccounts = async () => {
    this.accounts = await AuthApi.accounts();
  };

  @action public setQuery = (query: string) => {
    this.filters.query = query;

    window.history.replaceState(null, '', `?query=${query}`);

    this.loadAssetsDebounce();
  };

  @action public init = async () => {
    try {
      await this.loadAccounts();
      await this.loadAssets();
    } catch (e: any) {
      notification.error({
        message: 'load assets error',
        description: e.toString(),
      });
    } finally {
      this.loading = false;
    }
  };

  @action public loadAssets = async () => {
    try {
      const response = await AssetApi.find(
        this.filters.query,
        this.pagination.offset,
        this.pagination.limit,
      );

      if (response.assets) {
        this.assets = response.assets.map((asset) => {
          asset.accountName =
            this.accounts.find((account) => account.id === asset.account_id)
              ?.name || '';

          return asset;
        });
      } else {
        this.assets = [];
      }

      this.pagination = response.pagination;
    } catch (e: any) {
      notification.error({
        message: 'error load assets',
        description: e.toString(),
      });
    } finally {
    }
  };

  @action loadMore = async () => {
    this.pagination.offset += this.pagination.limit;

    const response = await AssetApi.find(
      this.filters.query,
      this.pagination.offset,
      this.pagination.limit,
    );

    if (response.assets) {
      const processes = response.assets.map((asset) => {
        asset.accountName =
          this.accounts.find((account) => account.id === asset.account_id)
            ?.name || '';

        return asset;
      });

      this.assets.push(...processes);
    }
  };
}
