import { useState } from 'react';
import { Button, Load } from 'components/presenters';
import { observer } from 'mobx-react';
import { Content } from 'types/content';
import { CollibryApi } from 'core/services/api';

import styles from './content.module.less';

interface Props {
  content: Content;
}

export default observer(({ content }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const open = async () => {
    setLoading(true);

    const key = `contents/${content.asset_id}/${content.id}.mp4`;

    const { url } = await CollibryApi.share(key);

    window.open(url, '_blank');

    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <Load loading={loading}>
        <div className={styles.panel}>
          <div>
            <span>{content.index}</span>
          </div>
          <div>
            <span>{content.type}</span>
          </div>
        </div>
        <div>
          <span>{content.name}</span>
        </div>
        <div className={styles.url}>
          <Button onClick={open}>Open</Button>
        </div>
      </Load>
    </div>
  );
});
