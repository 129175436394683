import React from 'react';
import { DependencyStore } from '../../services/dependency.store';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { Table } from 'antd';
import { ModuleStore } from '../../services/module.store';
import Name from './name';
import Requirement from './requirement';
import DependencyType from './type';
import Size from './size';
import Overlap from './overlap';
import Actions from './actions';

interface Props {
  moduleStore: ModuleStore;
}

@inject('moduleStore')
@observer
class Dependencies extends React.Component<Props> {
  render() {
    const {
      moduleStore: { dependencies },
    } = this.props;

    return (
      <div>
        <Table<DependencyStore>
          title={() => <h3 style={{ margin: 0 }}>Dependencies</h3>}
          rowKey="id"
          columns={[
            {
              title: 'Name',
              render: (_, item) => <Name dependency={item} />,
            },
            {
              title: 'Requirement name',
              render: (_, item) => <Requirement dependency={item} />,
            },
            {
              title: 'Type',
              render: (_, item) => <DependencyType dependency={item} />,
            },
            {
              title: 'Size',
              render: (_, item) => <Size dependency={item} />,
            },
            {
              title: 'Overlap',
              render: (_, item) => <Overlap dependency={item} />,
            },
            {
              title: 'Actions',
              render: (_, item) => <Actions dependency={item} />,
            },
          ]}
          dataSource={dependencies}
          pagination={false}
        />
      </div>
    );
  }
}

export default observerWrapper(Dependencies);
