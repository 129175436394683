import { httpGet, httpPost, httpPut } from './http';
import { ADMIN_API_URL } from 'configs/api.config';
import { Module, ModuleDependency } from 'types/module';

export class ModuleApi {
  static async find(): Promise<{ modules: Module[] }> {
    return httpGet(`${ADMIN_API_URL}/module`);
  }

  static async findById(id: string): Promise<Module> {
    return httpGet(`${ADMIN_API_URL}/module/${id}`);
  }

  static async findDependencies(
    id: string,
  ): Promise<{ dependencies: ModuleDependency[] }> {
    return httpGet(`${ADMIN_API_URL}/module/${id}/dependencies`);
  }

  static async create(name: string, description: string): Promise<Module> {
    return httpPost(`${ADMIN_API_URL}/module`, {
      body: JSON.stringify({
        name,
        description,
      }),
    });
  }

  static async editDependency(
    id: string,
    dependencyId:string,
    dependency: ModuleDependency,
  ): Promise<ModuleDependency> {
    return httpPut(`${ADMIN_API_URL}/module/${id}/dependencies/${dependencyId}`, {
      body: JSON.stringify({
        ...dependency,
      }),
    });
  }
}
