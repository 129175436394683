import React from 'react';
import { Modal, notification, Select } from 'antd';
import { Input, Load } from 'components/presenters';
import { Process } from 'types/process';

import styles from './create-result.module.less';

const TYPES = ['json', 'media'];

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (name: string, processId: string, type: string) => void;
  processes: Process[];
  creating: boolean;
}

interface State {
  name: string;
  type: string;
  processId: string;
}

const defaultState: State = {
  processId: '',
  name: '',
  type: TYPES[0],
};

class CreateResult extends React.PureComponent<Props, State> {
  state: State = defaultState;

  onChangeName = (e: any) => {
    this.setState({ name: e.target.value });
  };

  onChangeProcess = (processId: string) => {
    this.setState({ processId });
  };

  onChangeType = (type: string) => {
    this.setState({ type });
  };

  onSave = () => {
    const { onSave } = this.props;
    const { name, processId, type } = this.state;

    if (!name) {
      notification.error({
        message: 'create error',
        description: 'name is required',
      });
      return;
    }

    if (!processId) {
      notification.error({
        message: 'create error',
        description: 'process is required',
      });
      return;
    }

    onSave(name, processId, type);
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState(defaultState);

    onClose();
  };

  render() {
    const { visible, processes, creating } = this.props;
    const { name, processId, type } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={this.onClose}
        title="Create process"
        onOk={this.onSave}
        footer={creating ? <div /> : undefined}
      >
        <Load loading={creating}>
          <div className={styles.body}>
            <div className={styles.form}>
              <div className={styles.formItem}>
                <Input
                  onChange={this.onChangeName}
                  size="large"
                  placeholder="Enter result name..."
                  value={name}
                />
              </div>
              <div className={styles.formItem}>
                <Select
                  size="large"
                  placeholder="please choose type"
                  value={type}
                  onChange={this.onChangeType}
                >
                  {TYPES.map((type) => (
                    <Select.Option key={type}>{type}</Select.Option>
                  ))}
                </Select>
              </div>
              <div className={styles.formItem}>
                <Select
                  size="large"
                  placeholder="Select process..."
                  onChange={this.onChangeProcess}
                  value={processId || undefined}
                >
                  {processes.map((process) => (
                    <Select.Option key={process.id} value={process.id}>
                      {process.flowName} - {process.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </div>
        </Load>
      </Modal>
    );
  }
}

export default CreateResult;
