import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { InfoStore } from '../../services/info.store';
import { useEffect } from 'react';
import { Button, Load } from 'components/presenters';

import styles from './segments.module.less';

interface InternalProps {
  infoStore: InfoStore;
}

interface ExternalProps {
  setStep: () => void;
}

type Props = InternalProps & ExternalProps;

const Segments = inject('infoStore')(
  observer((props: Props) => {
    useEffect(() => {
      props.infoStore.loadSegments();
    }, []);

    const openLogs = (id: string) => () => {
      props.infoStore.openLogs(id);
    };

    return (
      <div className={styles.container}>
        <div className={styles.panel}>
          <Button onClick={props.setStep}>Close</Button>
        </div>
        <Load loading={props.infoStore.isLoading}>
          {props.infoStore.segments.map((segment) => (
            <div
              className={styles.item}
              onClick={openLogs(segment.id)}
              key={segment.id}
            >
              <h4>{segment.index}</h4>
              <h4>{segment.start}</h4>
              <h4>{segment.end}</h4>
              <h4>{segment.status}</h4>
              <h4>{segment.processingTime}</h4>
            </div>
          ))}
        </Load>
      </div>
    );
  }),
);

export default observerWrapper<ExternalProps>(Segments);
