import React from 'react';
import { Navigate, Route, Routes as BrowserRoutes } from 'react-router-dom';
import { ROUTES_MAP } from './config';

import { Header } from './features/header';

import { Login } from './pages/login';
import { Assets } from './pages/assets';
import { Asset } from './pages/asset';
import { ComingSoon } from './pages/coming-soon';
import { Costs } from './pages/costs';
import { Modules } from './pages/modules';
import { Module } from './pages/module';

interface Props {
  loggedIn: boolean;
}

export const Routes = (props: Props) => {
  if (props.loggedIn) {
    return (
      <React.Fragment>
        <Header />
        <BrowserRoutes>
          <Route path={ROUTES_MAP.assets} element={<Assets />} />
          <Route path={ROUTES_MAP.asset} element={<Asset />} />
          <Route path={ROUTES_MAP.comingSoon} element={<ComingSoon />} />
          <Route path={ROUTES_MAP.costs} element={<Costs />} />
          <Route path={ROUTES_MAP.modules} element={<Modules />} />
          <Route path={ROUTES_MAP.module} element={<Module />} />
          <Route path="*" element={<Navigate to={ROUTES_MAP.assets} />} />
        </BrowserRoutes>
      </React.Fragment>
    );
  } else {
    return (
      <BrowserRoutes>
        <Route path={ROUTES_MAP.signin} element={<Login />} />
        <Route path="*" element={<Navigate to={ROUTES_MAP.signin} />} />
      </BrowserRoutes>
    );
  }
};
