export interface Module {
  id: string;
  name: string;
  description: string;
  created_at: string;
  config: { version: string };
}

export interface ModuleDependency {
  id: string;
  module_id: string;
  name: string;
  overlap: number;
  requirement_id: string;
  size: number;
  type: ModuleDependencyType;
  config: {
    save_type: string;
  };
}

export enum ModuleDependencyType {
  asyncmap = 'asyncmap',
  map = 'map',
  block = 'block',
  config = 'config',
}
