import React from 'react';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { AssetStore } from '../../services/asset.store';
import ResultComponent from '../result/result';
import { Collapse, notification } from 'antd';
import ResultsVersions from '../results-versions/results-versions';
import CreateVersion from '../create-version/create-version';
import { Result, ResultFile } from 'types/result';
import { Button } from 'components/presenters';
import CreateResult from '../create-result/create-result';

import styles from './results.module.less';
import { AWSApi } from '../../../../../core/services/aws/aws';

interface Props {
  assetStore: AssetStore;
}

interface State {
  createVersionVisible: boolean;
  createResultVisible: boolean;
  uploading: boolean;
}

@inject('assetStore')
@observer
class Results extends React.Component<Props, State> {
  state: State = {
    createVersionVisible: false,
    createResultVisible: false,
    uploading: false,
  };

  result: Result | null = null;

  setCreateVersionVisible =
    (visible: boolean, result: Result | null = null) =>
    () => {
      this.setState({ createVersionVisible: visible });
      this.result = result;
    };

  setCreateResultVisible = (visible: boolean) => () => {
    this.setState({ createResultVisible: visible });
  };

  createVersion = async (file: ResultFile) => {
    if (!this.result) {
      notification.error({
        message: 'create error',
        description: 'result is empty',
      });

      return;
    }

    if (this.result.type === 'json') {
      const json = await file.file.text();

      await this.props.assetStore.createVersion(this.result, json);
    } else {
      this.setState({ uploading: true });
      const version = await this.props.assetStore.createVersion(
        this.result,
        '',
      );

      const ext = file.name.split('.').pop();

      const key = `${this.result.id}/${version.id}.${ext}`;

      await AWSApi.asyncUploadObject(file.file, key);

      this.setState({ uploading: false });
    }

    this.setCreateVersionVisible(false)();
  };

  createResult = async (name: string, processId: string, type: string) => {
    await this.props.assetStore.createResult(name, processId, type);

    this.setCreateResultVisible(false)();
  };

  render() {
    const { assetStore } = this.props;
    const { createVersionVisible, createResultVisible, uploading } = this.state;

    return (
      <div>
        <Button
          type="primary"
          size="large"
          onClick={this.setCreateResultVisible(true)}
        >
          Create result
        </Button>
        <Collapse className={styles.collapse} accordion destroyInactivePanel>
          {assetStore.results.map((result) => (
            // @ts-ignore
            <Collapse.Panel
              className={styles.panel}
              header=""
              key={result.id}
              showArrow={false}
              extra={<ResultComponent key={result.id} result={result} />}
            >
              <ResultsVersions
                result={result}
                onCreate={this.setCreateVersionVisible(true, result)}
              />
            </Collapse.Panel>
          ))}
          <CreateVersion
            visible={createVersionVisible}
            onSave={this.createVersion}
            result={this.result}
            onCancel={this.setCreateVersionVisible(false)}
            uploading={uploading}
          />
          <CreateResult
            visible={createResultVisible}
            creating={false}
            onClose={this.setCreateResultVisible(false)}
            onSave={this.createResult}
            processes={assetStore.processes}
          />
        </Collapse>
      </div>
    );
  }
}

export default observerWrapper<{}>(Results);
