import { action, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { AssetApi } from 'core/services/api';
import { Feature } from 'types/feature';
import { Segment } from 'types/segment';
import { ADMIN_API_URL } from '../../../../../../../../configs/api.config';
import moment from 'moment';

export class InfoStore {
  private readonly assetId: string;
  private readonly processId: string;
  private featureId?: string;

  @observable public isLoading: boolean = true;
  @observable public features: Feature[] = [];
  @observable public segments: Segment[] = [];

  constructor(assetId: string, processId: string) {
    this.assetId = assetId;
    this.processId = processId;

    makeObservable(this);
  }

  @action public init = () => {
    this.loadFeatures();
  };

  @action public loadFeatures = async () => {
    this.isLoading = true;

    try {
      const features = await AssetApi.findFeatures(
        this.assetId,
        this.processId,
      );

      this.features = features || [];
      this.isLoading = false;
    } catch (e: any) {
      notification.error({
        message: 'error load features',
        description: e.toString(),
      });
    }
  };

  @action public loadSegments = async () => {
    this.isLoading = true;

    try {
      const segments = await AssetApi.findSegments(
        this.assetId,
        this.processId,
        this.featureId || '',
      );

      this.segments = (segments || []).map((segment) => ({
        ...segment,
        processingTime: segment.processing_end
          ? moment
              .utc(
                moment(segment.processing_end).diff(
                  moment(segment.processing_start),
                ),
              )
              .format('mm:ss.SSS')
          : '-',
      }));
      this.isLoading = false;
    } catch (e: any) {
      notification.error({
        message: 'error load features',
        description: e.toString(),
      });
    }
  };

  @action public setFeature = (featureId: string) => {
    this.featureId = featureId;
  };

  @action public openLogs = (id: string) => {
    const url = `${ADMIN_API_URL}/logs/${this.processId}/${
      this.featureId
    }/${id}?token=${localStorage.getItem('apiKey') || ''}`;
    window.open(url, '_blank');
  };
}
