import { DependencyStore } from '../../services/dependency.store';
import { observer } from 'mobx-react';
import { Button } from 'components/presenters';
import React from 'react';

import styles from './dependencies.module.less';

interface Props {
  dependency: DependencyStore;
}

const Actions = observer(({ dependency }: Props) => {
  if (dependency.isView) {
    return (
      <div className={styles.actions}>
        <Button onClick={dependency.edit}>Edit</Button>
        <Button type="primary" danger>
          Remove
        </Button>
      </div>
    );
  }

  return (
    <div className={styles.actions}>
      <Button type="primary" onClick={dependency.save}>
        Save
      </Button>
      <Button onClick={dependency.cancel} type="primary" danger>
        Cancel
      </Button>
    </div>
  );
});

export default Actions;
