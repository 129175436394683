import { DependencyStore } from '../../services/dependency.store';
import { Select } from 'antd';
import { ModuleDependencyType } from 'types/module';
import { observer } from 'mobx-react';

interface Props {
  dependency: DependencyStore;
}

const DependencyType = observer(({ dependency }: Props) => {
  if (dependency.isView) {
    return <div>{dependency.dependency.type}</div>;
  }

  return (
    <Select value={dependency.dependency.type} onChange={dependency.setType}>
      {Object.values(ModuleDependencyType).map((type) => (
        <Select.Option key={type} value={type}>
          {type}
        </Select.Option>
      ))}
    </Select>
  );
});

export default DependencyType;
