import { ModuleDependency, ModuleDependencyType } from 'types/module';
import { action, computed, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { ModuleApi } from 'core/services/api';

enum State {
  view = 'view',
  editing = 'editing',
  creating = 'creating',
}

export class DependencyStore {
  private oldDependency: ModuleDependency;

  public id: string;

  @observable private state: State = State.view;

  @observable public dependency: ModuleDependency;

  constructor(dependency: ModuleDependency, state: State = State.view) {
    this.id = dependency.id;

    this.dependency = dependency;
    this.oldDependency = dependency;
    this.state = state;

    makeObservable(this);
  }

  @computed public get isEditing() {
    return this.state === State.editing;
  }

  @computed public get isNew() {
    return this.state === State.creating;
  }

  @computed public get isView(): boolean {
    return this.state === State.view;
  }

  @action public edit = () => {
    this.state = State.editing;
  };

  @action public cancel = () => {
    this.state = State.view;
    this.dependency = { ...this.oldDependency };
  };

  @action public save = async () => {
    try {
      const dependency = await ModuleApi.editDependency(
        this.dependency.module_id,
        this.id,
        this.dependency,
      );
      this.state = State.view;

      this.dependency = dependency;
      this.oldDependency = dependency;
    } catch (e: any) {
      notification.error({ message: 'save error', description: e.toString() });
    }
  };

  @action public setName = (name: string) => {
    this.dependency.name = name;
  };

  @action public setRequirementId = (requirementId: string) => {
    this.dependency.requirement_id = requirementId;
  };

  @action public setType = (type: ModuleDependencyType) => {
    if (this.dependency.type !== type) {
      this.setRequirementId('');
    }
    this.dependency.type = type;
  };

  @action public setSize = (size: number | null) => {
    if (size) this.dependency.size = size;
  };

  @action public setOverlap = (overlap: number | null) => {
    if (overlap) this.dependency.overlap = overlap;
  };
}
