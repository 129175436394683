import { httpDelete, httpGet, httpPost, httpPut } from './http';
import { ADMIN_API_URL } from 'configs/api.config';
import { Cost } from 'types/cost';

export class CostApi {
  static async find(): Promise<{ costs: Cost[] }> {
    return httpGet(`${ADMIN_API_URL}/cost`);
  }

  static async create(flowId: string, cost: number): Promise<Cost> {
    return httpPost(`${ADMIN_API_URL}/cost`, {
      body: JSON.stringify({
        flow_id: flowId,
        cost,
      }),
    });
  }

  static async remove(id: string) {
    return httpDelete(`${ADMIN_API_URL}/cost/${id}`);
  }

  static async edit(id: string, cost: number) {
    return httpPut(`${ADMIN_API_URL}/cost/${id}`, {
      body: JSON.stringify({
        cost,
      }),
    });
  }
}
