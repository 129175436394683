import { observer } from 'mobx-react';
import { Process } from 'types/process';
import moment from 'moment';
import { DEFAULT_TIME_WITHOUT_MILLIS_FORMAT } from 'configs/time.config';
import { Modal, Popover, Tag } from 'antd';
import { Button } from 'components/presenters';

import styles from './process.module.less';
import { useState } from 'react';
import Info from './components/info/info';

interface Props {
  process: Process;
  onRemove: (process: Process) => void;
  onStop: (process: Process) => void;
}

export default observer(({ process, onRemove, onStop }: Props) => {
  const [open, setOpen] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const getTag = (status: string) => {
    switch (status) {
      case 'completed':
        return <Tag color="green">{status}</Tag>;
      case 'failed':
        return <Tag color="red">{status}</Tag>;
      default:
        return <Tag color="blue">{status}</Tag>;
    }
  };

  const handleRightClick = (e: any) => {
    e.preventDefault();

    setOpen(true);

    return false;
  };

  const close = (visible: boolean) => {
    if (!visible) {
      setOpen(false);
    }
  };

  const remove = () => {
    onRemove(process);
    setOpen(false);
  };

  const stop = () => {
    onStop(process);
  };

  const setInfoVisible = (visible: boolean) => () => {
    setInfoOpen(visible);
  };

  return (
    <Popover
      title={process.title}
      visible={open}
      onVisibleChange={close}
      trigger="click"
      content={
        <div className={styles.contextMenu}>
          {(process.status === 'failed' || process.status === 'completed') && (
            <Button type="primary" danger onClick={remove}>
              Remove
            </Button>
          )}

          {(process.status === 'processing' || process.status === 'ready') && (
            <Button type="primary" danger onClick={stop}>
              Stop
            </Button>
          )}
        </div>
      }
    >
      <div
        className={styles.container}
        onContextMenu={handleRightClick}
        onClick={setInfoVisible(true)}
      >
        <div className={styles.flow}>
          <div>
            <p>{process.flowName}</p>
          </div>
          <div>{getTag(process.status)}</div>
        </div>
        <div className={styles.title}>
          <div>
            <p>{process.title}</p>
          </div>
        </div>
        <div className={styles.times}>
          <div>
            <span>
              {moment(process.created_at).format(
                DEFAULT_TIME_WITHOUT_MILLIS_FORMAT,
              )}
            </span>
          </div>
          <div>
            <span>{process.processingTime}</span>
          </div>
        </div>
      </div>
      <Modal visible={infoOpen} onCancel={setInfoVisible(false)}>
        <Info assetId={process.asset_id} processId={process.id} />
      </Modal>
    </Popover>
  );
});
