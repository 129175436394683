import { Input, InputNumber, InputProps } from 'antd';

const InputWrapper = (props: InputProps) => {
  return <Input {...props} />;
};

InputWrapper.TextArea = Input.TextArea;
InputWrapper.Number = InputNumber;

export default InputWrapper;
