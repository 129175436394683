import React from 'react';

import styles from './coming-soon.module.less';

class ComingSoon extends React.Component {
  render() {
    return (
      <div className={styles.container}>
        <h1>COMING SOON</h1>
      </div>
    );
  }
}

export default ComingSoon;
