import cn from 'classnames';

import styles from './container.module.less';

interface Props {
  children: any;
  className?: string;
  block?: boolean;
}

function Container({ children, className, block }: Props) {
  return (
    <div className={cn(styles.container, block && styles.block, className)}>
      {children}
    </div>
  );
}

export default Container;
