import { RootStore } from 'core/stores/root.store';
import React from 'react';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from 'components/app';

import './styles/app.less';

configure({
  enforceActions: 'never',
});

const rootStore = new RootStore();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider {...rootStore}>
    <App />
  </Provider>,
);

reportWebVitals();
