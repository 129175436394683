export const httpGet = async (url: string, config = {}) => {
  try {
    const response = await fetch(url, {
      headers: {
        'api-token': `${localStorage.getItem('apiKey') || ''}`,
      },
      ...config,
    });

    if (response.ok) {
      return response.json();
    }

    throw await response.text();
  } catch (e: any) {
    console.error('API ERROR: ', e);
    throw e;
  }
};

export const httpPost = async (url: string, config = {}) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'Application/json',
        'api-token': `${localStorage.getItem('apiKey') || ''}`,
      },
      ...config,
    });

    if (response.ok) {
      return response.json();
    }

    throw await response.text();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const httpPut = async (url: string, config = {}) => {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'Application/json',
        'api-token': `${localStorage.getItem('apiKey') || ''}`,
      },
      ...config,
    });

    if (response.ok) {
      return response.json();
    }

    throw await response.text();
  } catch (e) {
    throw e;
  }
};

export const httpDelete = async (url: string, config = {}) => {
  try {
    const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'Application/json',
        'api-token': `${localStorage.getItem('apiKey') || ''}`,
      },
      ...config,
    });

    if (response.ok) {
      return response.json();
    }

    throw await response.text();
  } catch (e) {
    throw e;
  }
};
