import { Modal } from 'antd';
import { Input } from 'components/presenters';
import { CostsStore } from '../../services/costs.store';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';

interface Props {
  costsStore: CostsStore;
}

const EditCost = inject('costsStore')(
  observer(({ costsStore }: Props) => {
    return (
      <Modal
        title="Edit cost"
        onCancel={() => costsStore.setEditing(null)}
        visible={!!costsStore.editingCost}
        onOk={costsStore.edit}
      >
        <Input.Number
          style={{ width: '100%' }}
          size="large"
          value={costsStore.editingCost?.cost}
          onChange={costsStore.setEditingCostValue}
        />
      </Modal>
    );
  }),
);

export default observerWrapper(EditCost);
