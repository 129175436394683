import { observer } from 'mobx-react';
import { DependencyStore } from '../../services/dependency.store';
import { Input } from 'components/presenters';

interface Props {
  dependency: DependencyStore;
}

const Size = observer(({ dependency }: Props) => {
  if (dependency.isView) {
    return <div>{dependency.dependency.size}</div>;
  }

  return (
    <Input.Number
      precision={0}
      min={0}
      value={dependency.dependency.size}
      onChange={dependency.setSize}
    />
  );
});

export default Size;
