import { observer } from 'mobx-react';
import { DependencyStore } from '../../services/dependency.store';
import { Input } from 'components/presenters';

interface Props {
  dependency: DependencyStore;
}

const Name = observer(({ dependency }: Props) => {
  if (dependency.isView) {
    return <div>{dependency.dependency.name}</div>;
  }

  const handleChange = (e: any) => {
    dependency.setName(e.target.value);
  };

  return <Input value={dependency.dependency.name} onChange={handleChange} />;
});

export default Name;
