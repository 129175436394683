import { ROUTES_MAP } from 'components/config';
import { setLink } from 'core/services/url/link';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Module } from 'types/module';
import cn from 'classnames';
import { DEFAULT_TIME_FORMAT } from 'configs/time.config';

import styles from './modules-item.module.less';

interface Props {
  module: Module;
}

@observer
class ModulesItem extends React.Component<Props> {
  render() {
    const { module } = this.props;

    const link = setLink(ROUTES_MAP.module, { id: module.id });

    return (
      <Link to={link}>
        <div className={cn(styles.item)}>
          <div className={styles.title}>
            <h4>{module.name}</h4>
          </div>
          <div className={styles.type}>
            <span>{module.description}</span>
          </div>
          <div>
            <div className={styles.footer}>
              <span>
                created: {moment(module.created_at).format(DEFAULT_TIME_FORMAT)}
              </span>

              {module.config.version ? (
                <span>Version {module.config.version}</span>
              ) : (
                <span>not deployed</span>
              )}
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default ModulesItem;
