import { AssetStore } from './asset.store';
import { action, makeObservable, observable } from 'mobx';
import { Account } from 'types/account';
import { notification } from 'antd';
import { AssetApi, AuthApi } from 'core/services/api';

export class CopyStore {
  private assetStore: AssetStore;

  @observable public visible: boolean = false;
  @observable public isLoading: boolean = true;
  @observable public accounts: Account[] = [];
  @observable public selectedAccount?: string;

  constructor(assetStore: AssetStore) {
    this.assetStore = assetStore;

    makeObservable(this);
  }

  @action public init = async () => {
    try {
      this.accounts = await AuthApi.accounts();
    } catch (e: any) {
      notification.error({ message: 'error init', description: e.toString() });
    } finally {
      this.isLoading = false;
    }
  };

  @action public open = () => {
    this.visible = true;
  };

  @action public close = () => {
    this.visible = false;
    this.selectedAccount = undefined;
  };

  @action public copy = async () => {
    try {
      this.isLoading = true;

      if (!this.selectedAccount) {
        notification.error({
          message: 'error copy',
          description: 'account is required',
        });

        return;
      }

      if (this.assetStore.asset && this.selectedAccount) {
        await AssetApi.copy(this.assetStore.asset?.id, this.selectedAccount);

        notification.success({
          message: 'success',
          description: 'asset was copied',
        });

        this.visible = false;
      }
    } catch (e: any) {
      notification.error({
        message: 'error copy',
        description: e.toString(),
      });
    } finally {
      this.isLoading = false;
    }
  };

  @action public setSelectedAccount = (accountId: string) => {
    this.selectedAccount = accountId;
  };
}
