import { notification } from 'antd';
import { action, makeAutoObservable, observable } from 'mobx';
import { AuthStore } from './auth.store';
import { RootStore } from './root.store';

export class AppStore {
  private authStore: AuthStore;

  @observable public isReady: boolean = false;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);

    this.authStore = rootStore.authStore;
  }

  @action public init = async () => {
    try {
      const token = localStorage.getItem('apiKey');

      if (token) {
        await this.authStore.verify(token);
      }
    } catch (e: any) {
      notification.error({
        message: 'error verify',
        description: e.toString(),
      });
    } finally {
      this.isReady = true;
    }
  };
}
