import { AssetStore } from '../../services/asset.store';
import { inject, observer } from 'mobx-react';
import React from 'react';
import { Button } from 'components/presenters';
import { PlusOutlined } from '@ant-design/icons';
import { observerWrapper } from 'observer-wrapper';
import ProcessComponent from '../process/process';
import CreateProcess from '../create-process/create-process';
import { Process } from 'types/process';

import styles from './processes.module.less';

interface Props {
  assetStore: AssetStore;
}

interface State {
  visibleCreate: boolean;
}

@inject('assetStore')
@observer
class Processes extends React.Component<Props, State> {
  state: State = {
    visibleCreate: false,
  };

  setVisible = (visible: boolean) => () => {
    this.setState({ visibleCreate: visible });
  };

  onSave = async (
    title: string,
    flowId: string,
    contentId: string,
    isDummy: boolean,
  ) => {
    await this.props.assetStore.createProcess(
      title,
      flowId,
      contentId,
      isDummy,
    );

    this.setVisible(false)();
  };

  remove = (process: Process) => {
    this.props.assetStore.removeProcess(process);
  };

  stop = (process: Process) => {
    this.props.assetStore.stopProcess(process);
  };

  render() {
    const { assetStore } = this.props;
    const { visibleCreate } = this.state;

    return (
      <div className={styles.list}>
        {assetStore.processes.map((process) => (
          <ProcessComponent
            key={process.id}
            process={process}
            onRemove={this.remove}
            onStop={this.stop}
          />
        ))}
        <Button
          className={styles.createButton}
          type="dashed"
          onClick={this.setVisible(true)}
        >
          <PlusOutlined />
        </Button>
        <CreateProcess
          visible={visibleCreate}
          onClose={this.setVisible(false)}
          flows={assetStore.flows}
          contents={assetStore.contents}
          onSave={this.onSave}
          creating={assetStore.creatingProcess}
        />
      </div>
    );
  }
}

export default observerWrapper<{}>(Processes);
