import { ButtonProps } from 'antd';
import { Button } from 'antd';

interface Props extends ButtonProps {
  children: any;
}

const ButtonWrapper = (props: Props) => {
  return <Button {...props}>{props.children}</Button>;
};

export default ButtonWrapper;
