import { AppStore } from "./app.store";
import { AuthStore } from "./auth.store";

export class RootStore {
  public appStore: AppStore;
  public authStore: AuthStore;

  constructor() {
    this.authStore = new AuthStore();
    this.appStore = new AppStore(this);
  }
}
