import { inject, observer } from 'mobx-react';
import { Result, ResultVersion } from 'types/result';
import { Table } from 'antd';
import moment from 'moment';
import { DEFAULT_TIME_FORMAT } from 'configs/time.config';
import { Button } from 'components/presenters';
import { AssetStore } from '../../services/asset.store';
import { observerWrapper } from 'observer-wrapper';
import { toJS } from 'mobx';

interface ExternalProps {
  result: Result;
  onCreate: () => void;
}

type Props = ExternalProps & {
  assetStore: AssetStore;
};

const columns = [
  {
    title: 'Version',
    render: (version: ResultVersion) => `V${version.version}`,
  },
  {
    title: 'Is original',
    render: (version: ResultVersion) => `${version.is_original}`,
  },
  {
    title: 'Created at',
    render: (version: ResultVersion) =>
      moment(version.created_at).format(DEFAULT_TIME_FORMAT),
  },
];

export default observerWrapper<ExternalProps>(
  inject('assetStore')(
    observer(({ result, assetStore, onCreate }: Props) => {
      const download = (version: ResultVersion) => () => {
        assetStore.download(result, version);
      };

      const open = (version: ResultVersion) => () => {
        assetStore.open(result, version);
      };

      return (
        <div>
          <Button type="primary" onClick={onCreate}>
            Create version
          </Button>
          <Table
            key="id"
            pagination={false}
            dataSource={toJS(result.versions)}
            columns={[
              ...columns,
              {
                title: '',
                render: (version: ResultVersion) =>
                  result.type === 'media' ? (
                    <Button type="primary" onClick={open(version)}>
                      Open
                    </Button>
                  ) : (
                    <Button type="primary" onClick={download(version)}>
                      Download
                    </Button>
                  ),
              },
            ]}
          />
        </div>
      );
    }),
  ),
);
