import { AuthApi } from 'core/services/api';
import { action, makeAutoObservable, observable } from 'mobx';
import { Account } from 'types/account';

export class AuthStore {
  @observable public account?: Account;
  @observable public loggedIn: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  @action public verify = async (token: string) => {
    const account = await AuthApi.verify(token);

    if (account.type !== 'system' && account.type !== 'owner') {
      throw new Error('this token not admin');
    }

    this.account = account;

    localStorage.setItem('apiKey', account.api_token);
    this.loggedIn = true;
  };

  @action public logout = async () => {
    localStorage.removeItem('apiKey');
    this.loggedIn = false;
  };
}
