import React, { ChangeEvent } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { ModulesStore } from '../services/modules.store';
import { Link, useParams } from 'react-router-dom';
import { Load, Input, Button } from 'components/presenters';
import ModulesList from '../components/modules-list/modules-list';
import { setLink } from 'core/services/url/link';
import { ROUTES_MAP } from 'components/config';

import styles from './modules.module.less';

interface Props {
  modulesStore: ModulesStore;
}

@inject('modulesStore')
@observer
class Modules extends React.Component<Props> {
  componentDidMount() {
    this.props.modulesStore.init();
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.modulesStore.setQuery(event.target.value);
  };

  render() {
    const { modulesStore } = this.props;

    return (
      <Load loading={modulesStore.isLoading}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.panel}>
              <Input
                size="large"
                className={styles.search}
                placeholder="search..."
                onChange={this.handleChange}
                value={modulesStore.filters.query}
              />
              <div>
                <Link to={setLink(ROUTES_MAP.module, { id: 'new' })}>
                  <Button shape="round" size="large" type="primary">
                    Create
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              <ModulesList />
            </div>
          </div>
        </div>
      </Load>
    );
  }
}

const ModulesWrapper = observerWrapper(Modules);

function Wrapper() {
  const params = useParams();

  const id = params['id'] || '';

  const modulesStore = new ModulesStore(id);

  return (
    <Provider modulesStore={modulesStore}>
      <ModulesWrapper />
    </Provider>
  );
}

export default Wrapper;
