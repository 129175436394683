import { action, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { Asset } from 'types/asset';
import { AssetApi, AuthApi, FlowApi } from 'core/services/api';
import { Account } from 'types/account';
import { Content } from 'types/content';
import { Process } from 'types/process';
import { Flow } from 'types/flow';
import moment from 'moment';
import { Result, ResultVersion } from 'types/result';
import { ROUTES_MAP } from 'components/config';

export class AssetStore {
  private readonly id: string;

  public accounts: Account[] = [];
  public flows: Flow[] = [];

  @observable public asset?: Asset;
  @observable public loading: boolean = true;
  @observable public creatingProcess: boolean = false;
  @observable public contents: Content[] = [];
  @observable public processes: Process[] = [];
  @observable public results: Result[] = [];

  constructor(id: string) {
    this.id = id;

    makeObservable(this);
  }

  private mapProcess = (process: Process): Process => {
    const flow = this.flows.find((flow) => flow.id === process.flow_id);

    if (flow) {
      process.flowName = flow.name;
    }

    if (process.finished_at) {
      const start = moment(process.created_at);
      const end = moment(process.finished_at);

      if (end > start) {
        process.processingTime = moment
          .utc(end.diff(start).valueOf())
          .format('HH:mm:ss.SSS');
      }
    }

    return process;
  };

  private mapResult = (result: Result, processId: string): Result => {
    result.createdDate = new Date(result.created_at);

    const process = this.processes.find((item) => item.id === processId);

    if (process) {
      result.processTitle = process.title;
      result.processId = process.id;
    }

    return result;
  };

  @action private loadAccounts = async () => {
    this.accounts = await AuthApi.accounts();
  };

  @action private loadFlows = async () => {
    const response = await FlowApi.find();

    this.flows = response.flows;
  };

  @action private loadContents = async () => {
    const response = await AssetApi.findContents(this.id);

    this.contents = response.contents.map((content, index) => {
      content.index = `#${index + 1}`;
      return content;
    });
  };

  @action private loadProcesses = async () => {
    const response = await AssetApi.findProcesses(this.id);

    this.processes = response.processes.map(this.mapProcess);
  };

  @action private loadProcessResults = async (processId: string) => {
    const results = await AssetApi.findResults(this.id, processId);

    return results.map((result) => {
      return this.mapResult(result, processId);
    });
  };

  @action private loadAllResults = async () => {
    const promises: Promise<any>[] = [];

    this.processes.forEach((process) =>
      promises.push(this.loadProcessResults(process.id)),
    );

    const results: Result[] = [];

    const processesResults = await Promise.all(promises);

    processesResults.forEach((items) => results.push(...items));

    this.results = results.sort(
      (a, b) => b.createdDate.valueOf() - a.createdDate.valueOf(),
    );
  };

  @action public init = async () => {
    try {
      await Promise.all([this.loadAccounts(), this.loadFlows()]);

      const asset = await AssetApi.findById(this.id);

      const account = this.accounts.find(
        (item) => item.id === asset.account_id,
      );

      if (account) {
        asset.accountName = account.name;
      }

      this.asset = asset;

      await Promise.all([this.loadContents(), this.loadProcesses()]);
      await this.loadAllResults();
    } catch (e: any) {
      notification.error({
        message: 'error load asset',
        description: e.toString(),
      });
    } finally {
      this.loading = false;
    }
  };

  @action public createProcess = async (
    title: string,
    flowId: string,
    contentId: string,
    isDummy: boolean,
  ) => {
    try {
      this.creatingProcess = true;

      const process = await AssetApi.createProcess(
        this.id,
        title,
        flowId,
        {
          type: 'content',
          item: contentId,
        },
        isDummy,
      );

      this.processes.unshift(this.mapProcess(process));
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });

      throw e;
    } finally {
      this.creatingProcess = false;
    }
  };

  @action public download = async (
    result: Result,
    resultVersion: ResultVersion,
  ) => {
    const json = await AssetApi.downloadVersion(
      this.id,
      result.processId,
      result.id,
      resultVersion.id,
    );

    const fileType = 'application/json';
    const fileName = `${result.name}-v${resultVersion.version}.json`;

    const content = JSON.stringify(json, null, 2);
    const blob = new Blob([content], { type: fileType });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');

    link.href = url;
    link.download = fileName || url;
    link.click();
  };

  @action public open = async (
    result: Result,
    resultVersion: ResultVersion,
  ) => {
    const json = await AssetApi.downloadVersion(
      this.id,
      result.processId,
      result.id,
      resultVersion.id,
    );

    window.open(json.url, '_blank');
  };

  @action public createVersion = async (result: Result, json: string) => {
    try {
      const obj = JSON.parse(json || '{}');

      const version = await AssetApi.createVersion(
        this.id,
        result.processId,
        result.id,
        Object.keys(obj).length ? obj : null,
      );

      if (!result.versions) {
        result.versions = [];
      }

      result.versions.push(version);

      const index = this.results.findIndex((item) => item.id === result.id);

      if (index !== -1) {
        this.results[index] = result;
      }

      return version;
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });
      throw e;
    } finally {
    }
  };

  @action public createResult = async (
    name: string,
    processId: string,
    type: string,
  ) => {
    try {
      const result = await AssetApi.createResult(
        this.id,
        processId,
        name,
        type,
      );

      this.results.unshift(this.mapResult(result, processId));
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });
      throw e;
    } finally {
    }
  };

  @action public createContent = async (url: string) => {
    try {
      const content = await AssetApi.createContent(this.id, url);

      this.contents.unshift(content);
    } catch (e: any) {
      notification.error({
        message: 'create error',
        description: e.toString(),
      });

      throw e;
    } finally {
    }
  };

  @action public removeProcess = async (process: Process) => {
    try {
      const removingProcess = await AssetApi.removeProcess(this.id, process.id);

      const index = this.processes.indexOf(process);

      this.processes[index] = this.mapProcess(removingProcess);
    } catch (e: any) {
      notification.error({
        message: 'remove process error',
        description: e.toString(),
      });
    }
  };

  @action public remove = async () => {
    this.loading = true;
    try {
      await AssetApi.remove(this.id);
      window.location.href = ROUTES_MAP.assets;
    } catch (e: any) {
      notification.error({
        message: 'remove error',
        description: e.toString(),
      });
      this.loading = false;
    } finally {
    }
  };

  @action public stopProcess = async (process: Process) => {
    try {
      await AssetApi.stopProcess(this.id, process.id);
    } catch (e: any) {
      notification.error({
        message: 'stop error',
        description: e.toString(),
      });
    } finally {
    }
  };
}
