import React from 'react';
import { Result } from 'types/result';
import moment from 'moment/moment';
import { DEFAULT_TIME_WITHOUT_MILLIS_FORMAT } from 'configs/time.config';

import styles from './result.module.less';

interface Props {
  result: Result;
}

class ResultComponent extends React.Component<Props> {
  render() {
    const { result } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.title}>
          <p>{result.name}</p>
        </div>
        <div className={styles.info}>
          <div className={styles.process}>
            <p>{result.processTitle}</p>
          </div>
          <div className={styles.date}>
            <p>
              {moment(result.created_at).format(
                DEFAULT_TIME_WITHOUT_MILLIS_FORMAT,
              )}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default ResultComponent;
