import { AssetStore } from '../../services/asset.store';
import React from 'react';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import Content from '../content/content';
import { PlusOutlined } from '@ant-design/icons';
import { Button } from 'components/presenters';

import styles from './contents.module.less';
import CreateContent from '../create-content/create-content';

interface Props {
  assetStore: AssetStore;
}

interface State {
  visible: boolean;
}

@inject('assetStore')
@observer
class Contents extends React.Component<Props, State> {
  state: State = {
    visible: false,
  };

  setVisible = (visible: boolean) => () => {
    this.setState({ visible });
  };

  create = async (url: string) => {
    await this.props.assetStore.createContent(url);
    this.setVisible(false)();
  };

  render() {
    const { assetStore } = this.props;
    const { visible } = this.state;

    return (
      <div className={styles.list}>
        {assetStore.contents.map((content) => (
          <Content key={content.id} content={content} />
        ))}
        <Button
          className={styles.createButton}
          type="dashed"
          onClick={this.setVisible(true)}
        >
          <PlusOutlined />
        </Button>
        <CreateContent
          visible={visible}
          onSave={this.create}
          onClose={this.setVisible(false)}
        />
      </div>
    );
  }
}

export default observerWrapper<{}>(Contents);
