import React from 'react';
import { Modal, Upload, UploadFile } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { Result, ResultFile } from 'types/result';

interface ResultVersionFile extends ResultFile {
  uploadFile: UploadFile;
}

interface Props {
  visible: boolean;
  onSave: (file: ResultFile) => void;
  onCancel: () => void;
  result: Result | null;
  uploading: boolean;
}

interface State {
  filelist: ResultVersionFile[];
}

class CreateVersion extends React.Component<Props, State> {
  state: State = {
    filelist: [],
  };

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (this.props.result?.id !== prevProps.result?.id) {
      this.setState({ filelist: [] });
    }
  }

  handleRemove = (file: UploadFile) => {
    const filelist = this.state.filelist.filter(
      ({ name }) => file.name !== name,
    );
    this.setState({ filelist });
  };

  handleBeforeUpload = (file: UploadFile, files: UploadFile[]) => {
    return false;
  };

  handleChange = ({ fileList }: { fileList: UploadFile[] }) => {
    const filelist: ResultVersionFile[] = fileList.map((item) => ({
      name: item.name,
      file: item.originFileObj as File,
      uploadFile: item,
    }));

    this.setState({ filelist });
  };

  onSave = async () => {
    const { onSave } = this.props;
    const { filelist } = this.state;

    if (filelist.length) {
      onSave(filelist[0]);
    }
  };

  render() {
    const { visible, result, onCancel, uploading } = this.props;
    const { filelist } = this.state;

    return (
      <Modal
        title="create version"
        open={visible}
        onOk={this.onSave}
        okButtonProps={{ disabled: !filelist.length }}
        onCancel={onCancel}
        confirmLoading={uploading}
      >
        <div>
          <Upload.Dragger
            name="file"
            accept={result?.type === 'media' ? 'video/*,.mkv' : 'json'}
            maxCount={1}
            onRemove={this.handleRemove}
            beforeUpload={this.handleBeforeUpload}
            onChange={this.handleChange}
            fileList={filelist.map((file) => file.uploadFile)}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">Support {result?.type} files</p>
          </Upload.Dragger>
        </div>
      </Modal>
    );
  }
}

export default CreateVersion;
