import React from 'react';
import { useParams } from 'react-router-dom';
import { observerWrapper } from 'observer-wrapper';
import { AssetStore } from '../services/asset.store';
import { inject, observer, Provider } from 'mobx-react';
import Container from '../components/container/container';
import { Dropdown, Menu, MenuProps } from 'antd';
import { Button, Load } from 'components/presenters';
import { DownOutlined } from '@ant-design/icons';
import moment from 'moment/moment';
import { DEFAULT_TIME_FORMAT } from 'configs/time.config';
import Copy from '../components/copy/copy';

import Contents from '../components/contents/contents';
import Processes from '../components/processes/processes';
import Results from '../components/results/results';

import styles from './asset.module.less';
import { CopyStore } from '../services/copy.store';

interface Props {
  assetStore: AssetStore;
  copyStore: CopyStore;
}

type MenuItem = Required<MenuProps>['items'][number];
function getItem(
  label: React.ReactNode,
  key: React.Key,
  onClick?: () => void,
  disabled?: boolean,
): MenuItem {
  return {
    key,
    label,
    className: styles.itemDropdownContainer,
    onClick,
    disabled,
  } as MenuItem;
}

@inject('assetStore', 'copyStore')
@observer
class Asset extends React.Component<Props> {
  componentDidMount() {
    this.props.assetStore.init();
  }

  getOptions = () => {
    const { assetStore, copyStore } = this.props;

    return [
      {
        title: 'Remove',
        onClick: () => {
          assetStore.remove();
        },
      },
      {
        title: 'Copy',
        onClick: () => {
          copyStore.open();
        },
      },
      {
        title: 'Stop',
        disabled: true,
      },
    ].map((item) => {
      return getItem(
        <div>{item.title}</div>,
        item.title,
        item.onClick,
        item.disabled,
      );
    });
  };

  render() {
    const { assetStore } = this.props;

    return (
      <div className={styles.container}>
        <Load loading={assetStore.loading}>
          <Container className={styles.panel}>
            <h1>{assetStore.asset?.title}</h1>
          </Container>

          <Container className={styles.introduction}>
            <h2>Introduction</h2>
            <div>
              <div className={styles.introductionInfo}>
                <div className={styles.info}>
                  <div className={styles.infoItem}>
                    <h4>{assetStore.contents.length}</h4>
                    <h5>Contents</h5>
                  </div>
                  <div className={styles.infoItem}>
                    <h4>{assetStore.processes.length}</h4>
                    <h5>Processes</h5>
                  </div>
                </div>
                <div className={styles.accountInfo}>
                  <p>{assetStore.asset?.accountName}</p>
                  <h6>
                    {moment(assetStore.asset?.created_at).format(
                      DEFAULT_TIME_FORMAT,
                    )}
                  </h6>
                </div>

                <div className={styles.actions}>
                  <Dropdown
                    arrow={{ pointAtCenter: true }}
                    placement="bottom"
                    overlay={<Menu items={this.getOptions()} />}
                    trigger={['click']}
                  >
                    <Button size="large">
                      Actions
                      <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </div>
          </Container>

          <div className={styles.contents}>
            <Container>
              <h2>Contents</h2>
              <div>
                <Contents />
              </div>
            </Container>
          </div>

          <div className={styles.processes}>
            <Container>
              <h2>Processes</h2>
              <div>
                <Processes />
              </div>
            </Container>
          </div>

          <div className={styles.results}>
            <Container>
              <h2>Results</h2>
              <div>
                <Results />
              </div>
            </Container>
            <Container className={styles.results}>
              <h2>Basic statistics</h2>
              <div></div>
            </Container>
          </div>
          <Copy />
        </Load>
      </div>
    );
  }
}

const AssetWrapper = observerWrapper<{}>(Asset);

function Wrapper() {
  const params = useParams();

  const id = params['id'] || '';

  const assetStore = new AssetStore(id);
  const copyStore = new CopyStore(assetStore);

  return (
    <Provider assetStore={assetStore} copyStore={copyStore}>
      <AssetWrapper />
    </Provider>
  );
}

export default Wrapper;
