import { BigHead } from '@bigheads/core';

const accessories = ['none', 'roundGlasses', 'tinyGlasses', 'shades'];
const bodies = ['chest', 'breasts'];
const clothing = ['naked', 'shirt', 'dressShirt', 'vneck', 'tankTop', 'dress'];
const clothingColors = ['white', 'blue', 'black', 'green', 'red'];
const eyebrows = ['raised', 'leftLowered', 'serious', 'angry', 'concerned'];
const eyes = [
  'normal',
  'leftTwitch',
  'happy',
  'content',
  'squint',
  'simple',
  'dizzy',
  'wink',
  'heart',
];
const facialHairs = ['none', 'none2', 'none3', 'stubble', 'mediumBeard'];
const hairs = [
  'none',
  'long',
  'bun',
  'short',
  'pixie',
  'balding',
  'buzz',
  'afro',
  'bob',
];
const hairColors = [
  'blonde',
  'orange',
  'black',
  'white',
  'brown',
  'blue',
  'pink',
];

const hats = ['none', 'none2', 'none3', 'none4', 'none5', 'beanie', 'turban'];

const hatsColors = ['white', 'blue', 'black', 'green', 'red'];

const lipColors = ['red', 'purple', 'pink', 'turqoise', 'green'];

const mouth = ['grin', 'sad', 'openSmile', 'lips', 'open', 'serious', 'tongue'];

const skin = ['light', 'yellow', 'brown', 'dark', 'red', 'black'];

const getParam = (arr: string[]): string => {
  const index = Math.floor(Math.random() * arr.length);

  return arr[index];
};

export const avatar = () => {
  return (
    <BigHead
      accessory={getParam(accessories)}
      body={getParam(bodies)}
      eyebrows={getParam(eyebrows)}
      clothing={getParam(clothing)}
      clothingColor={getParam(clothingColors)}
      eyes={getParam(eyes)}
      facialHair={getParam(facialHairs)}
      hair={getParam(hairs)}
      hairColor={getParam(hairColors)}
      hat={getParam(hats)}
      hatColor={getParam(hatsColors)}
      lipColor={getParam(lipColors)}
      mouth={getParam(mouth)}
      skinTone={getParam(skin)}
    />
  );
};
