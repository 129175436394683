export const setLink = (
  link: string,
  params: { [ket: string]: string },
): string => {
  let readyLink = link;

  Object.keys(params).forEach((key) => {
    const value = params[key];
    readyLink = readyLink.replaceAll(`:${key}`, value);
  });

  return readyLink;
};
