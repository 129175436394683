import {
  AWS_ACCESS_KEY,
  AWS_BUCKET,
  AWS_REGION,
  AWS_SECRET_KEY,
} from 'configs/api.config';
import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';

class AwsApi {
  private readonly s3Client: S3Client;

  constructor() {
    this.s3Client = new S3Client({
      region: AWS_REGION,
      credentials: {
        accessKeyId: AWS_ACCESS_KEY,
        secretAccessKey: AWS_SECRET_KEY,
      },
    });
  }

  public async asyncUploadObject(file: File, key: string) {
    const { uploader } = this.uploadObject(file, key);

    await uploader.done();
  }

  public uploadObject(file: File, key: string) {
    const uploader = new Upload({
      client: this.s3Client,
      queueSize: 3,
      params: {
        Key: key,
        Body: file,
        Bucket: AWS_BUCKET,
      },
    });

    return {
      key,
      uploader,
    };
  }
}

export const AWSApi = Object.freeze(new AwsApi());
