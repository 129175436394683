export const AUTH_API_URL: string = process.env.REACT_APP_AUTH_API_URL || '';
export const ADMIN_API_URL: string = process.env.REACT_APP_ADMIN_API_URL || '';
export const COLLIBRY_API_URL: string =
  process.env.REACT_APP_COLLIBRY_API_URL || '';

export const AWS_REGION: string = process.env.REACT_APP_AWS_REGION || '';
export const AWS_BUCKET: string = process.env.REACT_APP_AWS_BUCKET || '';
export const AWS_ACCESS_KEY: string =
  process.env.REACT_APP_AWS_ACCESS_KEY || '';
export const AWS_SECRET_KEY: string =
  process.env.REACT_APP_AWS_SECRET_KEY || '';
