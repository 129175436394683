import React from 'react';
import { inject, observer } from 'mobx-react';
import { AuthStore } from 'core/stores/auth.store';
import { observerWrapper } from 'observer-wrapper';
import { Link, matchPath, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { Dropdown, Menu, MenuProps } from 'antd';
import { Button } from 'components/presenters';
import { ROUTES_MAP } from 'components/config';
import { LogoutOutlined } from '@ant-design/icons';
import { avatar } from './avatar';

import styles from './header.module.less';

const items = [
  {
    title: 'Users control',
    isDropdown: true,
    options: [
      {
        title: 'Users',
        link: ROUTES_MAP.comingSoon,
      },
      {
        title: 'Accounts',
        link: ROUTES_MAP.comingSoon,
      },
      {
        title: 'Admins',
        link: ROUTES_MAP.comingSoon,
      },
      {
        title: 'Geo blocking',
        link: ROUTES_MAP.comingSoon,
      },
    ],
  },
  {
    title: 'Platform',
    isDropdown: true,
    options: [{ title: 'Assets', link: ROUTES_MAP.assets }],
  },
  {
    title: 'Infrastructure',
    isDropdown: true,
    options: [
      {
        title: 'Flows',
        link: ROUTES_MAP.comingSoon,
      },
      { title: 'Groups', link: ROUTES_MAP.comingSoon },
      {
        title: 'Modules',
        link: ROUTES_MAP.modules,
      },
      {
        title: 'Scaling',
        link: ROUTES_MAP.comingSoon,
      },
    ],
  },
  {
    title: 'Billing',
    isDropdown: true,
    options: [{ title: 'Costs', link: ROUTES_MAP.costs }],
  },
  { title: 'Rovar', link: ROUTES_MAP.comingSoon },
];

interface Props {
  authStore: AuthStore;
}

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
    className: styles.itemDropdownContainer,
  } as MenuItem;
}

const avatarUser = avatar();
const Avatar = () => avatarUser;

const Header = inject('authStore')(
  observer((props: Props) => {
    const { authStore } = props;

    const location = useLocation();

    return (
      <header className={styles.header}>
        <div className={styles.left}>
          <div className={styles.logo}>
            <Link to={ROUTES_MAP.assets}>
              <h2>Cognitivemill Cloud</h2>
            </Link>
          </div>
        </div>
        <div>
          <nav className={styles.nav}>
            {items.map((item) => {
              if (item.isDropdown) {
                let isActive = false;

                const options = item.options.map((option) => {
                  const isActiveItem = Boolean(
                    matchPath(option.link || '', location.pathname),
                  );

                  if (isActiveItem) {
                    isActive = true;
                  }

                  return getItem(
                    <Link
                      key={option.title}
                      to={option.link || ''}
                      type="link"
                      className={cn(
                        styles.itemDropdown,
                        isActiveItem && styles.active,
                      )}
                    >
                      {option.title}
                    </Link>,
                    option.title,
                  );
                });

                return (
                  <Dropdown
                    key={item.title}
                    className={cn(styles.item, isActive && styles.active)}
                    arrow={{ pointAtCenter: true }}
                    placement="bottom"
                    overlay={<Menu items={options} />}
                  >
                    <Button size="large" type="text">
                      {item.title}
                    </Button>
                  </Dropdown>
                );
              }

              const isActive = Boolean(
                matchPath(item.link || '', location.pathname),
              );

              return (
                <Link
                  key={item.title}
                  to={item.link || ''}
                  type="link"
                  className={cn(
                    styles.item,
                    styles.itemLink,
                    isActive && styles.active,
                  )}
                >
                  {item.title}
                </Link>
              );
            })}
          </nav>
        </div>
        <div>
          <Dropdown
            className={styles.item}
            arrow={{ pointAtCenter: true }}
            placement="bottom"
            overlay={
              <Menu
                items={[
                  getItem(
                    <Link
                      to="/logout"
                      type="link"
                      className={cn(styles.item, styles.logout)}
                      onClick={authStore.logout}
                    >
                      <span>
                        <LogoutOutlined size={18} /> logout
                      </span>
                    </Link>,
                    'logout',
                  ),
                ]}
              />
            }
          >
            <Button size="large" type="text" className={styles.avatar}>
              <Avatar key="avatar-user" />
              {authStore.account?.name}
            </Button>
          </Dropdown>
        </div>
      </header>
    );
  }),
);

export default observerWrapper<{}>(Header);
