import React from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { InfoStore } from './services/info.store';
import { observerWrapper } from 'observer-wrapper';
import Features from './components/features/features';
import Segments from './components/segments/segments';

interface ExternalProps {
  assetId: string;
  processId: string;
}

interface Props {
  infoStore: InfoStore;
}

enum Step {
  features = 'Features',
  segments = 'Segments',
}

interface State {
  step: Step;
}

@inject('infoStore')
@observer
class Info extends React.Component<Props, State> {
  state: State = {
    step: Step.features,
  };

  componentDidMount() {}

  openSegments = (featureId: string) => {
    this.props.infoStore.setFeature(featureId);
    this.setState({ step: Step.segments });
  };

  openFeatures = () => {
    this.setState({ step: Step.features });
  };

  render() {
    const { step } = this.state;

    return (
      <div>
        <h2>{step}</h2>
        {step === Step.features && <Features setStep={this.openSegments} />}
        {step === Step.segments && <Segments setStep={this.openFeatures} />}
      </div>
    );
  }
}

const ObserverInfo = observerWrapper(Info);

const wrapper = (props: ExternalProps) => {
  const infoStore = new InfoStore(props.assetId, props.processId);

  return (
    <Provider infoStore={infoStore}>
      <ObserverInfo />
    </Provider>
  );
};

export default wrapper;
