import React from 'react';
import { Checkbox, Modal, notification, Select } from 'antd';
import { Input, Load } from 'components/presenters';
import { Flow } from 'types/flow';
import { Content } from 'types/content';

import styles from './create-process.module.less';

interface Props {
  visible: boolean;
  onClose: () => void;
  onSave: (
    title: string,
    flowId: string,
    contentId: string,
    isDummy: boolean,
  ) => void;
  flows: Flow[];
  contents: Content[];
  creating: boolean;
}

interface State {
  title: string;
  flowId: string;
  contentId: string;
  isDummy: boolean;
}

const defaultState: State = {
  contentId: '',
  flowId: '',
  isDummy: false,
  title: '',
};

class CreateProcess extends React.PureComponent<Props, State> {
  state: State = defaultState;

  onChangeTitle = (e: any) => {
    this.setState({ title: e.target.value });
  };

  onChangeFlow = (flowId: string) => {
    this.setState({ flowId });
  };

  onChangeContent = (contentId: string) => {
    this.setState({ contentId });
  };

  onChangeIsDummy = (e: any) => {
    this.setState({ isDummy: e.target.checked });
  };

  onSave = () => {
    const { onSave } = this.props;
    const { title, flowId, contentId, isDummy } = this.state;

    if (!title) {
      notification.error({
        message: 'create error',
        description: 'title is required',
      });
      return;
    }

    if (!flowId) {
      notification.error({
        message: 'create error',
        description: 'flow is required',
      });
      return;
    }

    if (!contentId) {
      notification.error({
        message: 'create error',
        description: 'content is required',
      });
      return;
    }

    onSave(title, flowId, contentId, isDummy);
  };

  onClose = () => {
    const { onClose } = this.props;

    this.setState(defaultState);

    onClose();
  };

  render() {
    const { visible, flows, contents, creating } = this.props;
    const { title, contentId, flowId, isDummy } = this.state;

    return (
      <Modal
        visible={visible}
        onCancel={this.onClose}
        title="Create process"
        onOk={this.onSave}
        footer={creating ? <div /> : undefined}
      >
        <Load loading={creating}>
          <div className={styles.body}>
            <div className={styles.form}>
              <div className={styles.formItem}>
                <Input
                  onChange={this.onChangeTitle}
                  size="large"
                  placeholder="Enter process title..."
                  value={title}
                />
              </div>
              <div className={styles.formItem}>
                <Select
                  size="large"
                  placeholder="Select flow..."
                  onChange={this.onChangeFlow}
                  value={flowId || undefined}
                >
                  {flows.map((flow) => (
                    <Select.Option key={flow.id} value={flow.id}>
                      {flow.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className={styles.formItem}>
                <Select
                  size="large"
                  placeholder="Select content..."
                  onChange={this.onChangeContent}
                  value={contentId || undefined}
                >
                  {contents.map((content) => (
                    <Select.Option key={content.id} value={content.id}>
                      {content.index} - {content.name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div>
                <Checkbox onChange={this.onChangeIsDummy} checked={isDummy}>
                  Is dummy
                </Checkbox>
              </div>
            </div>
          </div>
        </Load>
      </Modal>
    );
  }
}

export default CreateProcess;
