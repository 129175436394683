import React from 'react';
import { Modal, Select } from 'antd';
import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { CopyStore } from '../../services/copy.store';

import styles from './copy.module.less';

interface Props {
  copyStore: CopyStore;
}

@inject('copyStore')
@observer
class Copy extends React.Component<Props> {
  componentDidMount() {
    this.props.copyStore.init();
  }

  render() {
    const { copyStore } = this.props;

    return (
      <Modal
        visible={copyStore.visible}
        onCancel={copyStore.close}
        confirmLoading={copyStore.isLoading}
        title="Copy"
        onOk={copyStore.copy}
      >
        <div className={styles.form}>
          <Select
            size="large"
            placeholder="Select account..."
            className={styles.select}
            showSearch
            filterOption={(input, option) => {
              const value = option?.children as unknown as string;

              return value.toLowerCase().includes(input.toLowerCase());
            }}
            value={copyStore.selectedAccount}
            onChange={copyStore.setSelectedAccount}
          >
            {copyStore.accounts.map((account) => (
              <Select.Option key={account.id} value={account.id}>
                {account.name}
              </Select.Option>
            ))}
          </Select>
        </div>
      </Modal>
    );
  }
}

const wrapper = observerWrapper<{}>(Copy);

export default wrapper;
