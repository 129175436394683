import React, { ChangeEvent } from 'react';
import { inject, observer, Provider } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { AssetsStore } from '../services/assets.store';
import { Load, Input } from 'components/presenters';
import { BackTop } from 'antd';
import { UpOutlined } from '@ant-design/icons';
import AssetsList from '../components/assets-list/assets-list';

import styles from './assets.module.less';

interface Props {
  assetsStore: AssetsStore;
}

@inject('assetsStore')
@observer
class Assets extends React.Component<Props> {
  componentDidMount() {
    this.props.assetsStore.init();
  }

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.props.assetsStore.setQuery(event.target.value);
  };

  render() {
    const { assetsStore } = this.props;

    return (
      <Load loading={assetsStore.loading}>
        <div className={styles.container}>
          <div className={styles.content}>
            <div>
              <Input
                size="large"
                className={styles.search}
                placeholder="search..."
                onChange={this.handleChange}
                value={assetsStore.filters.query}
              />
            </div>

            <AssetsList />
          </div>
        </div>
        <div>
          <BackTop duration={200}>
            <div className={styles.toTop}>
              <UpOutlined className={styles.toTopIcon} />
            </div>
          </BackTop>
        </div>
      </Load>
    );
  }
}

const AssetsWrapper = observerWrapper<{}>(Assets);

const wrapper = () => {
  const assetsStore = new AssetsStore();
  return (
    <Provider assetsStore={assetsStore}>
      <AssetsWrapper />
    </Provider>
  );
};

export default wrapper;
