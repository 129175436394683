import { inject, observer } from 'mobx-react';
import { observerWrapper } from 'observer-wrapper';
import { InfoStore } from '../../services/info.store';
import { useEffect } from 'react';
import { Load } from 'components/presenters';

import styles from './features.module.less';

interface InternalProps {
  infoStore: InfoStore;
}

interface ExternalProps {
  setStep: (featureId: string) => void;
}

type Props = InternalProps & ExternalProps;

const Features = inject('infoStore')(
  observer((props: Props) => {
    useEffect(() => {
      props.infoStore.loadFeatures();
    }, []);

    return (
      <Load loading={props.infoStore.isLoading}>
        {props.infoStore.features.map((feature) => (
          <div
            className={styles.item}
            key={feature.id}
            onClick={() => props.setStep(feature.id)}
          >
            <h4>{feature.type}</h4>
            <h4>{feature.status}</h4>
          </div>
        ))}
      </Load>
    );
  }),
);

export default observerWrapper<ExternalProps>(Features);
