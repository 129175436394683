import { action, computed, makeObservable, observable } from 'mobx';
import { notification } from 'antd';
import { Module } from 'types/module';
import { ModuleApi } from 'core/services/api';

export class ModulesStore {
  private id: string;

  @observable private allModules: Module[] = [];

  @observable public isLoading: boolean = true;
  @observable public filters: { query: string } = {
    query: new URLSearchParams(window.location.search).get('query') || '',
  };

  constructor(id: string) {
    this.id = id;

    makeObservable(this);
  }

  @computed public get modules(): Module[] {
    return this.allModules.filter(
      (module) =>
        module.name.toUpperCase().includes(this.filters.query.toUpperCase()) ||
        module.description
          .toUpperCase()
          .includes(this.filters.query.toUpperCase()),
    );
  }

  @action public init = async () => {
    try {
      const { modules } = await ModuleApi.find();

      this.allModules = modules;

      this.isLoading = false;
    } catch (e: any) {
      notification.error({ message: 'error init', description: e.toString() });
    }
  };

  @action public setQuery = (query: string) => {
    this.filters.query = query;

    window.history.replaceState(null, '', `?query=${query}`);
  };
}
