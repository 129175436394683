import { ROUTES_MAP } from 'components/config';
import { setLink } from 'core/services/url/link';
import { observer } from 'mobx-react';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { Asset } from 'types/asset';
import cn from 'classnames';
import { DEFAULT_TIME_FORMAT } from 'configs/time.config';

import styles from './assets-item.module.less';

interface Props {
  asset: Asset;
}

@observer
class AssetsItem extends React.Component<Props> {
  render() {
    const { asset } = this.props;

    const link = setLink(ROUTES_MAP.asset, { id: asset.id });

    return (
      <Link to={link}>
        <div className={cn(styles.item)}>
          <div className={styles.title}>
            <h4>{asset.title}</h4>
          </div>
          <div className={styles.type}>{/*<span>{process.type}</span>*/}</div>
          <div>
            <div className={styles.footer}>
              <span>
                created: {moment(asset.created_at).format(DEFAULT_TIME_FORMAT)}
              </span>
              <span className={styles.author}>By {asset.accountName}</span>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default AssetsItem;
