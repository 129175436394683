import { httpGet } from './http';

import { ADMIN_API_URL, AUTH_API_URL } from 'configs/api.config';
import { Account } from 'types/account';
import { User } from 'types/user';

export class AuthApi {
  static async verify(token: string): Promise<Account> {
    return httpGet(`${AUTH_API_URL}/account/verify/${token}`, {
      headers: undefined,
    });
  }

  static async accounts(): Promise<Account[]> {
    return httpGet(`${ADMIN_API_URL}/account`);
  }

  static async users(): Promise<User[]> {
    return httpGet(`${ADMIN_API_URL}/user`);
  }
}
