import React from 'react';
import { Module } from 'types/module';
import Dependencies from '../dependencies/dependencies';

interface Props {
  module: Module;
}

class ModuleContent extends React.Component<Props> {
  render() {
    const { module } = this.props;

    return (
      <div>
        <div>
          <h2>{module.name}</h2>
          <p>{module.description}</p>
        </div>
        <div>
          <Dependencies />
        </div>
      </div>
    );
  }
}

export default ModuleContent;
